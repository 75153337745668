<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable max-width="700px" persistent>
      <v-card>
        <v-card-title
          >{{ $t("checkmultidownload.listselectdownload")
          }}<v-spacer></v-spacer>
          <v-icon :color="color.theme" dense dark size="35"
            >mdi-download-multiple</v-icon
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-1 pt-1 pb-1" style="height: 500px;">
          <div class="text-center">
            <v-chip class="pt-4 pl-2 ma-2"
              ><p>
                <v-icon :color="color.theme"> folder </v-icon
                ><b>
                  {{ count_folder }} {{ $t("checkmultidownload.folder") }}</b
                >
              </p></v-chip
            >
            <v-chip class="pt-4 pl-2 ma-2"
              ><p>
                <v-icon :color="color.theme"> mdi-file</v-icon
                ><b> {{ count_file }} {{ $t("checkmultidownload.file") }}</b>
              </p></v-chip
            >
            <!-- <v-chip class="pt-4 pl-2 ma-2"><p ><v-icon color="green"> mdi-download-multiple </v-icon><b> {{countfile_multi}}  {{$t('checkmultidownload.file')}}</b></p></v-chip> -->
          <p v-if="check_downloading == true"> {{$t("checkmultidownload.downloading")}} {{ count_num }} / {{ count_filecandownload }}</p>
          <p v-if="check_downlaod_step3 == true"> {{$t("checkmultidownload.mergingfiles")}} </p>
          <p v-if="checkdownload_finish == true"> {{$t("checkmultidownload.downloadsuccess")}} </p>
          </div>
          <v-list flat>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in filecannotdownload" :key="i">
                <v-list-item-icon>
                  <v-icon color="red">{{
                    item.type === "folder" || item.file_type === "folder"
                      ? "folder"
                      : "mdi-file "
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      item.file_name.length >= 20
                        ? item.file_name.substring(0, 25) + "..."
                        : item.file_name
                    }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action
                  ><v-chip
                    small
                    label
                    outlined
                    class="text-right"
                    :color="
                      item.file_permission_2 !== '01' &&
                      item.status_lock_data === 'N'
                        ? color.theme
                        : 'red'
                    "
                    >{{
                      item.file_permission_2 !== "01" &&
                      item.status_lock_data === "N"
                        ? $t("checkmultidownload.candownload")
                        : item.file_permission_2 !== "01" &&
                          item.status_lock_data === "Y"
                        ? $t("checkmultidownload.cannotlockdownload")
                        : $t("checkmultidownload.cannotpermissiondownload")
                    }}</v-chip
                  ></v-list-item-action
                >
              </v-list-item>

              <v-list-item v-for="(item, i) in filelock" :key="i">
                <v-list-item-icon>
                  <v-icon color="red">{{
                    item.type === "folder" || item.file_type === "folder"
                      ? "folder"
                      : "mdi-file "
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      item.file_name.length >= 20
                        ? item.file_name.substring(0, 25) + "..."
                        : item.file_name
                    }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action
                  ><v-chip
                    small
                    label
                    outlined
                    class="text-right"
                    :color="
                      item.file_permission_2 !== '01' &&
                      item.status_lock_data === 'N'
                        ? color.theme
                        : 'red'
                    "
                    >{{
                      item.file_permission_2 !== "01" &&
                      item.status_lock_data === "N"
                        ? $t("checkmultidownload.candownload")
                        : item.file_permission_2 !== "01" &&
                          item.status_lock_data === "Y"
                        ? $t("checkmultidownload.cannotlockdownload")
                        : $t("checkmultidownload.cannotpermissiondownload")
                    }}</v-chip
                  ></v-list-item-action
                >
              </v-list-item>

              <v-list-item v-for="item in filecandownload" :key="item.title">
              <v-list-item-action @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                <v-icon large :color="item.file_icon[1]">{{ item.file_icon[0] }}</v-icon>
              </v-list-item-action>
              <v-badge
                :value="item.show_name"
                color="grey"
                :content="item.file_name"
                
                transition="slide-y-transition"
              ></v-badge>
              <v-list-item-content>
                 
                <v-list-item-title @mouseover="item.show_name = true" @mouseleave="item.show_name = false">
                  {{ item.file_name }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-progress-linear
                    v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                    :value="item.file_value"
                    color="light-blue"
                  ></v-progress-linear>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- โชว์ว่ามีสิทธิ์ดาวน์โหลดหรือไม่-->
              <v-list-item-action v-if="check_show_progress === false">
                <v-chip
                  class="ma-2 font-weight-bold"
                  :color="item.permission_download === true ? 'light-blue' : 'red'"
                  label
                  outlined
                >
                  {{ item.permission_download === true ? $t('checkmultidownload.candownload') : $t('checkmultidownload.candownload') }}
                </v-chip>
              </v-list-item-action>
              <!-- โชว์สถานะการลบว่าดาวน์โหลดสำเร็จหรือไม่ -->
              <v-list-item-action v-else>
                <v-chip v-if="item.file_value !== 500" class="ma-2 font-weight-bold" :color="color.theme" label outlined>
                  {{ item.file_status }}
                </v-chip>
                <v-chip v-else class="ma-2 font-weight-bold" color="red" label outlined>
                  {{ item.file_status }}
                </v-chip>
              </v-list-item-action>
            </v-list-item>
              <!-- <v-list-item v-for="(item, i) in filecandownload" :key="i">
                <v-list-item-icon>
                  <v-icon :color="color.theme">{{
                    item.type === "folder" || item.file_type === "folder"
                      ? "folder"
                      : "mdi-file "
                  }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{
                      item.file_name.length >= 20
                        ? item.file_name.substring(0, 25) + "..."
                        : item.file_name
                    }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                  <v-progress-linear
                    v-if="check_show_progress === true && item.file_value !== 100 && item.file_value !== 500"
                    :value="item.file_value"
                    color="light-blue"
                  ></v-progress-linear>
                </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action
                  ><v-chip
                    small
                    label
                    outlined
                    class="text-right"
                    :color="
                      item.file_permission_2 !== '01' &&
                      item.status_lock_data === 'N'
                        ? color.theme
                        : 'red'
                    "
                    >{{
                      item.file_permission_2 !== "01" &&
                      item.status_lock_data === "N"
                        ? $t("checkmultidownload.candownload")
                        : item.file_permission_2 !== "01" &&
                          item.status_lock_data === "Y"
                        ? $t("checkmultidownload.cannotlockdownload")
                        : $t("checkmultidownload.cannotpermissiondownload")
                    }}</v-chip
                  ></v-list-item-action
                >
                
              </v-list-item> -->
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            :dark="color.darkTheme"
            outlined
            @click="
              $emit('closedialog'),
                fn_clearshowdata(),
                (countfolder = []),
                (countfile = []),
                checkdownload_finish = true
            "
            >{{ $t("trashPage.canceldelete") }}</v-btn
          >
          <!-- :disabled="checkdownload_finish" -->
          <v-btn
            :color="color.theme"
            :dark="color.darkTheme"
            @click=" fn_multidownload_old(),(countfolder = []), (countfile = []),(check_show_progress = true)"
            v-show="count_filecandownload !== 0"
            >{{ $t("checkmultidownload.download") }}</v-btn
          >
          <!-- fn_multidownload(), -->
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogsnackbermultidowload
      :show="opensnackbarmultidownload"
      @closedialog="opensnackbarmultidownload = false"
      :percent="percent"
      :date="datetime"
      :btsdownload="btsdownload"
    ></dialogsnackbermultidowload>
  </v-row>
</template>
<script>
import download from "downloadjs";
import { saveAs } from "file-saver";
import { mapState, mapGetters } from "vuex";
import format from "date-fns/format";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import dialogsnackbermultidowload from "../optional/dialog-snackbarmultidowmload";
import gbfdecodearraybuffer from "@/globalFunctions/decodearraybuffer";
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    dialogsnackbermultidowload,
  },
  data() {
    return {
      check_downloading:false,
      check_downlaod_step3:false,
      checkdownload_finish:false,
      count_num:0,
      newlist:[],
      new_list:[],
      check_show_progress: false,
      count_filecandownload: 0,
      countfolder: [],
      countfile: [],
      count_folder: 0,
      count_file: 0,
      btsdownload: false,
      loaddataprogress: true,
      opensnackbarmultidownload: false,
      countfile_multi: 0,
      countfile_cannot: 0,
      totalfile: 0,
      percentCompleted: 0,
      percent: 0,
      datetime: "",
      filecandownload: [],
      filecannotdownload: [],
      filelock: [],
    };
  },
  props: ["show", "data"],
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
    ]),
    shower: {
      get() {
        if (this.show === true) {
          console.log("data", this.data);
          this.fn_showdata();
        }
        return this.show;
      },
    },
  },
  methods: {
    fn_clearshowdata() {
      this.filecandownload = [];
      this.filecannotdownload = [];
      this.filelock = [];
    },

    //วนเช็ค data เพื่อ show และนับจำนวนไฟล์โฟลเดอร์
    fn_showdata() {
      this.checkdownload_finish = false;
      this.countfolder = [];
      this.countfile = [];
      this.filecandownload = [];
      this.filecannotdownload = [];
      this.filelock = [];
      console.log("this.data", this.data);
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].file_status !== "M") {
          if (this.data[i].file_type === "folder") {
            this.countfolder.push(this.data[i]);
            console.log("this.countfolder", this.countfolder);
          } else {
            this.countfile.push(this.data[i]);
            console.log("this.countfolder", this.countfile);
          }
          this.count_folder = this.countfolder.length;
          this.count_file = this.countfile.length;
          if (
            this.data[i].file_permission_2 !== "01" &&
            this.data[i].status_lock_data === "N"
          ) {
            let datadownload = {};
            datadownload["file_name"] = this.data[i]["file_name"];
            datadownload["file_id"] = this.data[i]["file_id"];
            datadownload["file_icon"] = this.data[i]["file_icon"];
            datadownload["file_status"] = this.$t('checkmultidownload.downloading');
            datadownload["file_value"] = 0;
            datadownload["show_name"] = false;
            datadownload["file_type"] = this.data[i]["file_type"];
            datadownload["status_lock_data"] = this.data[i]["status_lock_data"];
            datadownload["permission_download"] = this.data[i]["file_permission_2"] !== "01" ? true : false;
            this.filecandownload.push(datadownload);
            this.count_filecandownload = this.filecandownload.length;
          } else if (
            this.data[i].file_permission_2 !== "01" &&
            this.data[i].status_lock_data === "Y"
          ) {
            this.filelock.push(this.data[i]);
          } else {
            this.filecannotdownload.push(this.data[i]);
          }
        }
      }
      console.log("ไฟล์ที่สามารถดาวน์โหลดได้", this.filecandownload);
      console.log("ไฟล์ที่ไม่สามารถดาวน์โหลดได้", this.filecannotdownload);
      this.countfile_multi = this.filecandownload.length;
      this.countfile_cannot = this.filecannotdownload.length;
      this.totalfile = this.data.length;
    },

    fn_zeroPadding(num, digit) {
      var zero = "";
      for (var i = 0; i < digit; i++) {
        zero += "0";
      }
      return (zero + num).slice(-digit);
    },

    //ดาวน์โหลดแบบ multi
    async fn_multidownload_old() {
      this.percentCompleted = 0;
      this.percent = 0;
      var d = new Date();
      this.datetime =
        d.toISOString().slice(0, 10) +
        " " +
        "at" +
        " " +
        this.fn_zeroPadding(d.getHours(), 2) +
        "-" +
        this.fn_zeroPadding(d.getMinutes(), 2) +
        "-" +
        this.fn_zeroPadding(d.getSeconds(), 2);

      //check ไฟล์ที่สามารถดาวน์โหลดได้
      let filedata = [];
      let filecandownload = [];
      for (let i = 0; i < this.data.length; i++) {
        if (this.data[i].file_status !== "M") {
          if (
            this.data[i].file_permission_2 !== "01" &&
            this.data[i].status_lock_data === "N"
          ) {
            filecandownload.push(this.data[i]);
          }
        }
      }
      // }
      console.log("ไฟล์ที่สามารถดาวน์โหลดได้", filecandownload);
      //จัดรูปแบบใหม่ส่งไปที่ api
      for (let i = 0; i < filecandownload.length; i++) {
        if (filecandownload[i].file_type === "folder") {
          filedata.push({
            id: filecandownload[i]["file_id"],
            type: filecandownload[i]["file_type"],
          });
        } else {
          filedata.push({
            id: filecandownload[i]["file_id"],
            type: "file",
          });
        }
      }
      // console.log("fileส่งไปที่api",filedata);
      if (filedata.length !== 0) {
        let payload = {
          list: filedata,
          account_id: this.dataAccountId,
        };
        this.btsdownload = true;
        this.opensnackbarmultidownload = true;
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(
            process.env.VUE_APP_SERVICE_DOWNLOAD_MULTIPLE +
              "/api/download_multiples_all",
            payload,
            {
              onDownloadProgress: (progressEvent) => {
                let progresspercent = parseInt(
                  Math.round((progressEvent.loaded / progressEvent.total) * 100)
                );
                if (progresspercent >= 95) {
                  this.percentCompleted = 95;
                } else {
                  this.percentCompleted = progresspercent;
                }
                this.percent = this.percentCompleted;
                console.log(this.percent);
              },
              withCredentials: false,
              responseType: "arraybuffer",
              headers: { Authorization: auth.code },
            }
          )
          .then((response) => {
            if (response.statusText === "OK") {
              console.log("response", response);
              if (
                parseInt(response.headers["content-length"]) < 700 &&
                parseInt(response.headers["content-type"]) ===
                  "application/json"
              ) {
                // if(response.headers["content-type"] !== "application/json"){
                //   this.opensnackbarmultidownload = false;
                //   Toast.fire({ icon: "error", title: "ไม่สามารถดาวน์โหลดไฟล์ เนื่องจากไฟล์เสีย"});
                // }else{
                console.log(parseInt(response.headers["content-length"]));
                this.opensnackbarmultidownload = false;
                let res_error = gbfdecodearraybuffer.decodearraybuffer(
                  response.data
                );
                Toast.fire({ icon: "error", title: res_error.errorMessage });
                // }
              } else {
                this.percentCompleted = 100;
                this.percent = this.percentCompleted;
                this.btsdownload = false;
                let headers = response.headers;
                let blob = new Blob([response.data], {
                  type: headers["content-type"],
                });
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = this.datetime;
                link.click();

                // console.log(this.percent);
                // const blob = new Blob([response.data]);
                // const content = response.headers["content-type"];
                // saveAs(blob, this.datetime + ".zip");
                setTimeout(() => {
                  this.opensnackbarmultidownload = false;
                }, 2500);
              }
            } else {
              this.opensnackbarmultidownload = false;
            }
            // this.opensnackbarmultidownload = false;
            this.fn_clearmultipledownload();
            this.fn_clearshowdata();
          })
          .catch((error) => {
            console.log(error);
            this.btsdownload = false;
            this.opensnackbarmultidownload = false;
            Toast.fire({
              icon: "error",
              title: this.$t("myinboxPage.msg.apierror"),
            });
          });
      } else {
        this.opensnackbarmultidownload = false;
        Toast.fire({
          icon: "error",
          title: this.$t("myinboxPage.msg.apierror"),
        });
      }
    },

    // async fn_multidownload() {
    //   this.checkdownload_finish = false;
    //   let filecandownload = [];
    //   let file_id = [];
    //   let folder_id = [];
    //   console.log("this.data",this.data);
    //   console.log("this.data",this.data);
    //   for (let i = 0; i < this.data.length; i++) {
    //     if (this.data[i].file_status !== "M") {
    //       if (
    //         this.data[i].file_permission_2 !== "01" &&
    //         this.data[i].status_lock_data === "N"
    //       ) {
    //         if(this.data[i].file_type === 'folder'){
    //           folder_id.push(this.data[i].file_id)
    //         }else{
    //           file_id.push(this.data[i].file_id)
    //         }
    //         filecandownload.push(this.data[i]);
    //       }
    //     }
    //   }
    //   console.log("filecandownload",filecandownload);
    //   console.log("file_id",file_id);

    //   this.newlist = [];

    //   for (let i = 0; i < this.filecandownload.length; i++) {
    //     let datadowload = {};
    //     datadowload["fileid"] = this.filecandownload[i].file_id;
    //     datadowload["name"] = this.filecandownload[i].file_name;
    //     datadowload["value"] = 0;
    //     this.newlist.push(datadowload);
    //   }

    //     for (let i = 0; i < this.filecandownload.length; i++) {
    //       this.dataIndex = this.newlist.findIndex((obj) => obj.name === this.filecandownload[i].file_name);
    //     }
    //     console.log("this.dataIndex",this.dataIndex);
    //     this.opensnackbar = true;
    //     this.new_list = this.newlist;

    //     let payload = {
    //       account_id: this.dataAccountId,
    //       list_file: file_id,
    //       list_folder: folder_id
    //     };
    //     let url =
    //       process.env.VUE_APP_SERVICE_DOWNLOAD_FILE + "/api/request/download_file_list"

    //     //this.btsdownload = true;
    //     this.opensnackbar = true;
    //     this.new_list = this.newlist;
    //     let timeout = 4000;

    //     this.axios
    //       .post(url,payload, {})
    //       .then((response) => {
    //         console.log(response.data);
    //         if (response.data.status === "OK") {
    //           this.fn_loop_downloading(response.data.all_file, response.data.zip_name, response.data.url);
    //         } else {
    //           this.btsdownload = false;
    //           this.opensnackbar = false;
    //           Toast.fire({
    //             icon: "error",
    //             title: "ไม่สามารถดาวน์โหลด ",
    //           });
    //         }
    //       })
    //       .catch((error) => {
    //         this.btsdownload = false;
    //         this.opensnackbar = false;
    //         Toast.fire({
    //           icon: "error",
    //           title: "ไม่สามารถดาวน์โหลด "
    //         });
    //         console.log("err", error);
    //       });
      
      
    // },
    async fn_loop_downloading(all_file, zip_name, url_link) {
      this.check_downloading = true;
      console.log("all_file",all_file);
      console.log("zip_name",zip_name);
      console.log("url_link",url_link);

      let total = 0;

      if (all_file.length === 0) {
        this.newlist[this.dataIndex]["value"] = 100;
        console.log(url_link);
        window.location.assign(url_link);
        this.opensnackbar = false;
      } else {
       
        console.log("all_file",all_file);
        for (let i = 0; i < all_file.length; i++) {
          this.count_num += 1;
          console.log("all_file.length", (1 / all_file.length) * 100, total);

          total += (1 / all_file.length) * 100;
          let res = await this.downloading_file(
            all_file[i]["file_id"],
            all_file[i]["path"],
            all_file[i]["location_file"],
            zip_name,
            all_file[i]["path"]
          );
          // let res = await this.downloading_file(
          //   url_link,
          //   zip_name,
          // );
          console.log(res);
          if (this.newlist[this.dataIndex]["value"] > 99) {
            this.newlist[this.dataIndex]["value"] = 99;
          } else {
            this.newlist[this.dataIndex]["value"] = Math.round(total);
          }
          this.newpercen = this.newlist[this.dataIndex]["value"];
          this.namefile = this.newlist[this.dataIndex]["name"];
          console.log(this.percentCompleted);
          console.log(i, all_file.length - 1);

          if (i === all_file.length - 1) {
            this.check_downloading = false;
            this.check_downlaod_step3 = true;
            this.download_finish(url_link,zip_name);
            // console.log(url_link);
            // window.location.assign(url_link);
            // this.opensnackbar = false;
          }
        }
      }
    },
    async downloading_file(file_id,path,location_file, zip_name, all_file) {
      let objIndex = this.filecandownload.findIndex((obj) => obj.file_id === file_id);
      if(objIndex == -1){
        objIndex = this.filecandownload.findIndex((obj) => obj.file_name === path)
      }
      console.log("objIndex",objIndex);
      let url =
          process.env.VUE_APP_SERVICE_DOWNLOAD_FOLDER +
          "/api/downloading_file?file_id=" +
          file_id +
          "&location_file=" +
          location_file +
          "&zip_name=" +
          zip_name +
          "&path=" +
          all_file +
          "&account_id=" + this.dataAccountId
      console.log(url);
 
        let result = await this.axios({
        method: "get",
        url: url,
        // onDownloadProgress: (progressEvent) => {
        //     let progresspercent = Math.round(
        //           (progressEvent.loaded * 100) / progressEvent.total
        //     );
        onDownloadProgress: (e) => {
          console.log((e.loaded / e.total) * 100 >= 95);
          if ((e.loaded / e.total) * 100 >= 99) {
            this.filecandownload[objIndex]["file_value"] = 100;
          } else {
            this.filecandownload[objIndex]["file_value"] = parseInt((e.loaded / e.total) * 100);
          }
        },
      });
      return new Promise((resolve, reject) => {
        try {
          console.log("result",result);
          if (result.data.status === "OK") {
            this.filecandownload[objIndex]["file_status"] = this.$t('checkmultidownload.downloadsuccess');
            this.filecandownload[objIndex]["file_value"] = 100;
             setTimeout(() => {
                  resolve({ status: "delete success", msg: "OK", code: "" });
                    }, 500);
            
          } else {
            this.filecandownload[objIndex]["file_status"] = this.$t('checkmultidownload.downloadnotsuccess');
            this.filecandownload[objIndex]["file_value"] = 500;
            setTimeout(() => {
                  resolve({ status: "delete denied", msg: ": ", code: "" });
                    }, 500);
            
          }
        } catch (err) {
          console.log("err",err);
          setTimeout(() => {
                   resolve({ status: "delete denied", msg: ": " + err, code: "" });
                    }, 500);
          this.filecandownload[objIndex]["file_status"] = this.$t('checkmultidownload.downloadnotsuccess');
          this.filecandownload[objIndex]["file_value"] = 500;
        }
      });

    },
    download_finish(url_folder, zip_name) {
    this.checkdownload_finish = false;
     console.log("url_folder",url_folder);
     console.log("zip_name",zip_name);
      let url = url_folder;
      console.log(url);
        this.axios.get(url, {
          withCredentials: false,
          responseType: "arraybuffer",
        }).then((response) => {
          console.log("testtt", response.statusText);
          console.log("response",response);
          console.log("response.data",response.data);
          // if(response.statusText === "OK"){
          if (response.status === 200) {
            try {
              let res_error = gbfdecodearraybuffer.decodearraybuffer(response.data);
              if (res_error.status === "ER") {
                this.check_downlaod_step3 = false;
                this.checkdownload_finish = true;
                Toast.fire({
                  icon: "error",
                  title: this.$t("toast.cannotconnectonebox"),
                });
              } else {
                this.check_downlaod_step3 = false;
                this.checkdownload_finish = true;
                this.newlist[this.dataIndex]["value"] = 100;
                let headers = response.headers;
                let blob = new Blob([response.data], { type: headers["content-type"],});
                console.log("blob",blob);
                let link = document.createElement("a");
                link.href = window.URL.createObjectURL(blob);
                link.download = format(new Date(), "yyyyMMddHHmmss");
                link.click();
              }
            } catch (error) {
              console.log("error", error);
              this.check_downlaod_step3 = false;
              this.checkdownload_finish = true;
              this.newlist[this.dataIndex]["value"] = 100;
              let headers = response.headers;
              let blob = new Blob([response.data], { type: headers["content-type"],});
              console.log("blob",blob);
              let link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = format(new Date(), "yyyyMMddHHmmss");
              link.click();
            }
            // this.check_downlaod_step3 = false;
            // this.checkdownload_finish = true;
            // this.newlist[this.dataIndex]["value"] = 100;
            // // console.log(url_folder);
            // // window.location.assign(url_folder);
            // let headers = response.headers;
            // let blob = new Blob([response.data], { type: headers["content-type"],});
            // console.log("blob",blob);
            // let link = document.createElement("a");
            // link.href = window.URL.createObjectURL(blob);
            // link.download = format(new Date(), "yyyyMMddHHmmss");
            // link.click();
          } else {
            this.check_downlaod_step3 = false;
            this.checkdownload_finish = true;
            Toast.fire({
              icon: "error",
              title: this.$t("toast.cannotconnectonebox"),
            });
          }
        });
    },
    //clearfile หลังจากดาวน์โหลดเสร็จ
    fn_clearmultipledownload() {
      this.data.splice(0, this.data.length);
      this.$emit("closedialog");
    },
  },
};
</script>
<style></style>
